<template>
  <div id="courier-detail-page">
    <vs-popup classContent="popup-example" :title="`${$t('change')} ${$t('password')}`" :active.sync="showModal">
      <template>  
        <div class="grid grid-cols-2 gap-4">
          <p>{{ $t('newPassword') }}: </p>
          <div>
            <vs-input type="password" v-validate="'required|min:6'" name="newPassword" ref="newPassword" class="w-full inputx mb-1" :placeholder="$t('newPassword')" v-model="newPassword" />
            <span class="text-danger text-sm" v-show="errors.has('newPassword')">{{ errors.first('newPassword') }}</span>
          </div>
          <p>{{ $t('confirmPassword') }}: </p>
          <div>
            <vs-input type="password" v-validate="'required|confirmed:newPassword'" name="confirmation" class="w-full inputx mb-1" :placeholder="$t('confirmPassword')" v-model="passwordConfirm" />
            <span class="text-danger text-sm" v-show="errors.has('confirmation')">{{ errors.first('confirmation') }}</span>
          </div>

        </div>
        <div class="flex mt-5">
          <vs-button @click="updatePassword()" color="primary" class="mt-5 ml-auto" type="filled">{{ $t('update') }}</vs-button>
        </div>
      </template>
    </vs-popup>
    <div v-if="isLoading" class="flex w-full h-full p-24">
      <img src="@/assets/images/loading1.gif" alt="" width="50" class="mx-auto">
    </div>
    <template v-else>
      <vs-alert color="danger" :active.sync="isActive">
        <span>{{ $t('wholesaler') }} {{ $t('isBlocked') }}. </span>
      </vs-alert>
      <vx-card class="mt-6">
        <vs-row class="mb-3">
          <h3 class="mr-auto">{{ wholesaler.name }}</h3>
          <vs-button v-if="$acl.check('superadmin')" type="border" color="warning" @click.prevent="changePassword" class="py-3 px-5 mb-4 mr-4 rounded-lg">
            <feather-icon icon="EditIcon" svgClasses="h-4 w-4" />
            {{ $t('changePassword') }}
          </vs-button>
          <vs-button v-if="$acl.check('superadmin') && wholesaler.isBlocked" type="border" color="primary" @click.prevent="confirmStatusChange" class="py-3 px-5 mb-4 mr-4 rounded-lg">
            <feather-icon icon="UnlockIcon" svgClasses="h-4 w-4" />
            {{ $t('unblock') }}
          </vs-button>
          <vs-button v-else-if="$acl.check('superadmin') && !wholesaler.isBlocked" type="border" color="danger" @click.prevent="confirmStatusChange" class="py-3 px-5 mb-4 mr-4 rounded-lg">
            <feather-icon icon="LockIcon" svgClasses="h-4 w-4" />
            {{ $t('block') }}
          </vs-button>
          <router-link v-if="$acl.check('superadmin')" :to="{name: 'wholesalerEdit', params: {id: $route.params.id}}" class="py-3 px-5  mb-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary">
            <feather-icon icon="EditIcon" svgClasses="h-4 w-4" />
            <span class="ml-2 text-base text-primary">{{ $t('edit') }}</span>
          </router-link>
          <vs-button v-if="$acl.check('superadmin') && wholesaler.isBlocked" type="filled" color="danger" @click.prevent="deleteSupplier" class="py-3 px-5 mb-4 ml-4 rounded-lg">
            <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" />
            {{ $t('delete') }}
          </vs-button>
        </vs-row>

        <vs-table stripe :data="wholesalerProps">
          <template slot="thead">
            <vs-th>{{ $t('property') }}</vs-th>
            <vs-th>{{ $t('value') }}</vs-th>
          </template>
          <template>
            <vs-tr v-for="(prop, idx) in wholesalerProps" :key="idx">
              <vs-td>
                <span class="uppercase font-medium">{{ $t(`props.${prop}`) }}</span>
              </vs-td>
              <vs-td v-if="wholesaler[prop] === true || wholesaler[prop] === false">
                <vs-chip :color="wholesaler[prop] ? 'success' : 'danger'">
                  <feather-icon v-if="wholesaler[prop]" icon="CheckIcon" svgClasses="w-5 h-5" />
                  <feather-icon v-else icon="XIcon" svgClasses="w-5 h-5" />
                </vs-chip>
              </vs-td>
              <vs-td v-else>
                {{ wholesaler[prop] }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vx-card>
      <vx-card v-if="orders.data.length" class="mt-5">
        <vs-table stripe hoverFlat ref="table" :data="data">
          <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
            <div class="flex">
              <h4 class="text-primary">{{ $t('orders') }}:</h4>
            </div>
            <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4">
              <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                <span class="mr-2">{{ currentx * itemsPerPage - (itemsPerPage - 1) }} - {{ orders.pagination.all - currentx * itemsPerPage > 0 ? currentx * itemsPerPage : orders.pagination.all }} of {{ orders.pagination.all }}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item @click="changeItemsPerPage(4)">
                  <span>4</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="changeItemsPerPage(10)">
                  <span>10</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="changeItemsPerPage(15)">
                  <span>15</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="changeItemsPerPage(20)">
                  <span>20</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>

          </div>

          <template slot="thead">
            <vs-th>{{ $t('fields.code') }}</vs-th>
            <vs-th>{{ $t('recipient') }}</vs-th>
            <vs-th>{{ $t('fields.total') }}</vs-th>
            <vs-th>{{ $t('deliveryTime') }}</vs-th>
            <vs-th>{{ $t('orderPage.rating') }}</vs-th>
            <vs-th>{{ $t('status') }}</vs-th>
            <vs-th>{{ $t('props.createdAt') }}</vs-th>
          </template>


          <template slot-scope="{data}">
            <vs-tr v-for="(tr, indextr) in data" :key="indextr" class="pt-3">
              <vs-td :data="data[indextr].code">
                <span class="font-medium">#{{ data[indextr].code }}</span>
              </vs-td>
              <vs-td :data="data[indextr]">
                {{ wholesaler.name }}
              </vs-td>

              <vs-td :data="data[indextr]">
                <strong>{{ data[indextr].total_price }}</strong>
              </vs-td>

              <vs-td :data="data[indextr].delivery_time">
                {{ data[indextr].delivery_time }}
              </vs-td>

              <vs-td :data="data[indextr].rating">
                {{ data[indextr].rating }}
                <feather-icon icon="StarIcon" svgClasses="text-warning h-5 w-5" />
              </vs-td>

              <vs-td>
                <vs-chip :color="getOrderStatusColor(data[indextr].status)" class="product-order-status">{{ data[indextr].status | title }}</vs-chip>
              </vs-td>

              <vs-td :data="data[indextr].createdAt">
                {{ data[indextr].createdAt  }}
              </vs-td>

              <template class="expand-order bg-primary" slot="expand">
                <div class="con-expand-orders w-full pl-3">
                  <div class="con-btns-user flex items-center justify-between">
                    <div class="con-userx flex items-center justify-start">
                      <!-- <vs-avatar :badge="tr.id" size="45px" :src="`https://randomuser.me/api/portraits/women/${indextr}.jpg`" /> -->
                      <span class="pt-1">{{ $t('order') }}: </span><h3>#{{ data[indextr].code }}</h3>
                    </div>
                    <div class="flex">
                      <feather-icon icon="EyeIcon" svgClasses="w-5 h-5 hover:text-success text-primary" @click.stop="showPage(tr.id)" />
                      <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary text-primary" class="ml-2" @click.stop="editData(tr.uuid)" />
                      <!-- <feather-icon icon="RepeatIcon" svgClasses="w-5 h-5 hover:text-primary text-primary" class="ml-2" @click.stop="changeStatus(tr.uuid, tr.isBlocked)" /> -->
                      <!--<vs-button type="border" size="small" icon-pack="feather" icon="icon-eye" class="mr-2" @click="showPage(data[indextr].id)"></vs-button>
                      <vs-button type="border" size="small" icon-pack="feather" icon="icon-edit" color="success" class="mr-2"></vs-button>
                      <vs-button type="border" size="small" icon-pack="feather" icon="icon-trash" color="danger"></vs-button> -->
                    </div>
                  </div>
                  <div class="items-container border rounded-lg overflow-hidden border-grey-light border-solid mt-3">
                    <div class="vx-row w-full ml-0 border-b border-0 border-solid border-grey-light pt-2 px-3 pb-1" style="background: #ededed;">
                      <div class="w-1/12 font-medium">#</div>
                      <div class="w-1/12 font-medium">{{ $t('fields.code') }}</div>
                      <div class="w-3/12 font-medium">{{ $t('fields.barcode') }}</div>
                      <div class="w-5/12 font-medium">{{ $t('fields.title') }}</div>
                      <div class="w-2/12 font-medium">{{ $t('fields.amount') }}</div>
                    </div>
                    <div v-for="(item, idx) in data[indextr].worder_products" :key="item.id" class="vx-row ml-0 p-3">
                      <div class="w-1/12">{{ idx + 1 }}</div>
                      <div class="w-1/12">{{ item.code }}</div>
                      <div class="w-3/12">{{ item.bar_code }}</div>
                      <div class="w-5/12">{{ item.name_tm }}</div>
                      <div class="w-2/12 font-semibold">{{ item.Woproducts.quantity }}{{ $t('pcs') }}</div>
                    </div>
                    <!-- <div class="vx-row ml-0 p-3">
                      <div class="w-1/12"> 1 </div>
                      <div class="w-1/12"> item.code </div>
                      <div class="w-8/12"> item.name_tm </div>
                      <div class="w-2/12 font-semibold"> 12</div>
                    </div> -->
                  </div>
                  <!-- <vs-list>
                    <vs-list-item>tr.email</vs-list-item>
                    <vs-list-item icon-pack="feather" icon="icon-globe" title="tr.website"></vs-list-item>
                  </vs-list> -->
                </div>
              </template>
            </vs-tr>
          </template>
        </vs-table>
        <vs-row class="mt-4">
          <vs-pagination :total="pages" :max="9" v-model="currentx" @change="paginateTo"></vs-pagination>
        </vs-row>
      </vx-card>
    </template>
  </div>
</template>

<script>
import vSelect from 'vue-select'
export default {
  components: {
    vSelect
  },
  data () {
    return {
      wholesaler: {},
      orders: { pagination: {}, data: [] },
      showModal: false,
      isLoading: true,
      newPassword: '',
      passwordConfirm: '',
      currentx: 1,
      itemsOffset: 0,
      itemsPerPage: 4,
      editingItems: false
    }
  },
  async created () {
    await this.fetchData()
    await this.fetchOrders()
  },
  computed: {
    isActive () { return this.wholesaler.isBlocked },
    data ()     { return this.orders.data },
    pages ()    { return Math.ceil(this.orders.pagination.all / this.itemsPerPage) },
    wholesalerProps () { return Object.keys(this.wholesaler).filter(el => el !== 'device_token') }
  },
  methods: {
    showPage (id) { this.$router.push(`/bulk-orders/${id}`) },
    editData (id) { this.$router.push(`/bulk-orders/${id}/edit`) },
    changeItemsPerPage (count) {
      this.itemsPerPage = count
      this.fetchOrders()
    },
    paginateTo () {
      this.itemsOffset = this.itemsPerPage * (this.currentx - 1)
      if (this.currentx !== 1) this.fetchOrders()
    },
    getOrderStatusColor (status) {
      if (status === 'on_hold')   return 'warning'
      if (status === 'pending')   return 'warning'
      if (status === 'rejected')   return 'danger'
      if (status === 'delivered') return 'success'
      if (status === 'not_delivered') return 'danger'
      if (status === 'canceled')  return 'danger'
      return 'primary'
    },
    async fetchOrders () {
      await this.$http.get('/wholesalers/orders', {
        params: {
          wholesalerId: this.wholesaler.id,
          limit: this.itemsPerPage,
          offset: this.itemsOffset
        }
      }).then(response => {
        this.orders = response.data
        // response.data.data.map(async (order) => {
        //   console.log(order.id)
        //   await this.$http.get('/wholesalers/order-products', {
        //     params: { worderId: Number(order.id) }
        //   }).then(res => {
        //     console.log(res)
        //     this.orders.data.push(res.data)
        //   }).catch(() => {
        //     console.log('error')
        //   })
        // })
      })
    },
    // async fetchProducts () {
    //   await this.$http.get('/products', {
    //     params: {
    //       type: 'express',
    //       isActive: true
    //     }
    //   }).then(response => {
    //     console.log('products')
    //     console.log(response)
    //     this.products = response.data.data
    //   })
    // },
    async fetchData () {
      await this.$http.get(`/wholesalers/${this.$route.params.id}`).then(response => {
        this.wholesaler = response.data
        this.isLoading = false
      }).catch(err => {
        this.isLoading = false
        this.notify('error', err.message)
      })

    },
    // async getItems (id) {
    //   console.log('getItems')
    //   await this.$http.get('/wholesalers/order-products', {
    //     params: { supplierorderId: id }
    //   }).then(res => {
    //     this.orderItems = res.data
    //   })
    // },
    deleteSupplier () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm delete',
        text: `You are about to delete wholesaler "${this.wholesaler.name}"`,
        accept: this.deleteData,
        acceptText: 'Delete'
      })
    },
    async deleteData () {
      await this.$http.delete(`/wholesalers/delete/${this.wholesaler.uuid}`).then(() => {
        this.notify('success', 'Wholesaler successfully deleted!')
        this.$router.back()
      }).catch(err => {
        this.notify('error', err.response.data.message)
      })
    },
    changePassword () {
      this.newPassword = ''
      this.passwordConfirm = ''
      this.editingItems = false
      this.showModal = true
    },
    async changeItems () {
      await this.fetchProducts()
      await this.fetchCombos()
      this.updatedItems.products = this.items.products
      this.updatedItems.collections = this.items.collections
      this.editingItems = true
      this.showModal = true
    },
    updatePassword () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$http.patch(`/wholesalers/edit/${this.wholesaler.uuid}`, {
            password: this.newPassword
          }).then(() => {
            this.showModal = false
            this.notify('success', `${this.$t('password')} ${this.$t('notify.sUpdated')}`)
            this.fetchData()
          }).catch(err => {
            this.notify('error', err.message)
          })
        } else {
          this.notify('error', this.$t('notify.formInvalid'))
        }
      })
    },
    confirmStatusChange () {
      this.$vs.dialog({
        type: 'confirm',
        color: this.wholesaler.isBlocked ? 'primary' : 'danger',
        title: `${this.wholesaler.isBlocked ? this.$t('unblock') : this.$t('block')}`,
        text: `${this.wholesaler.isBlocked ? this.$t('confirmUnblock') : this.$t('confirmBlock')} "${this.wholesaler.username}"`,
        accept: this.changeStatus,
        acceptText: this.$t('submit'),
        cancelText: this.$t('cancel')
      })
    },
    async changeStatus () {
      await this.$http.patch(`/wholesalers/edit/${this.wholesaler.uuid}`, {
        isBlocked: !this.wholesaler.isBlocked
      }).then(() => {
        this.fetchData()
        this.notify('success', `${this.$t('status')} ${this.$t('notify.sUpdated')}`)
      }).catch(err => {
        this.notify('error', err.message)
      })
    },
    notify (status, msg) {
      if (status === 'success') {
        this.$vs.notify({
          title: this.$t('notify.success'),
          text: msg,
          iconPack: 'feather',
          icon: 'icon-check-circle',
          color: 'primary'
        })
      } else {
        this.$vs.notify({
          title: this.$t('notify.error'),
          text: msg,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }
    }
  }
}
</script>
